import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../layouts/index"
import SEO from "../components/seo"

const ProjectsFr = props => {
  const { data } = props
  return (
    <Layout cssClass="projects blog-style">
      <SEO title="Projets" />
      <div className="main-content">
        <h1>Projets</h1>
        {data.allWordpressWpProjects.edges.map(({ node }) => (
          <div key={node.slug}>
            <Link to={`/projets/${node.slug}`} style={{ textDecoration: `none` }}>
              <h3>{node.title}</h3>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ProjectsFr

export const pageQuery = graphql`
  query {
    allWordpressWpProjects(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

ProjectsFr.propTypes = {
  data: PropTypes.object.isRequired,
}
